<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

body {
  margin:0;
}


.abs{   /* 绝对 */
  position:absolute;
}
.re{    /* 相对 */
  position:relative;
}
.divsimple{     /* 占位div */
  height: 30px;
}
.divheader{     /* 标题栏div */
  height: 40px;
  line-height: 33px;
  font-size: 30px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.width100{      /* 100%宽度 */
  width: 100%;
}
.textright{     /* 文本居右 */
  text-align: right
}
.textleft{     /* 文本居左 */
  text-align: left
}
.textcenter{     /* 文本居左 */
  text-align: center;
}
.fleft{         /* 左浮*/
  float: left;
}
.fright{        /* 右浮*/
  float: right;
}

</style>
