<template>
  <nav>
    <!--    <router-link to="/Nav/home">Home</router-link> |-->
    <!--    <router-link to="/Nav/about">About</router-link>-->

    <el-menu
        router
        :default-active="this.$router.path"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
    >
      <a class="logo" href="/" >NK的博客</a>

      <el-menu-item key="1" index="/navigation/first">主页</el-menu-item>
      <div class="flex-grow"/>
      <el-sub-menu v-for="(c_row,icount) in NavList" :index="c_row.id">
        <template #title>{{ c_row.title }}</template>
        <el-menu-item v-for="t_row in c_row.to_tags" :key="t_row.id" :index="tagsurl + t_row.id">{{
            t_row.title
          }}
        </el-menu-item>
      </el-sub-menu>

<!--      <el-menu-item key="about" index="/navigation/about">About</el-menu-item>-->
<!--      <el-menu-item key="markdown_show" index="/navigation/markdown_show">Markdown_show</el-menu-item>-->

<!--      <el-menu-item key="homepage" index="/Homepage">Homepage</el-menu-item>-->

<!--      <a :href="logouturl" style="display: block;position: absolute;right:30px;line-height: 2.5;padding: 10px 10px;color: #17a2b8;">-->
<!--        <span>Logout</span>-->
<!--      </a>-->


      <span v-if="userobj">
        <a @click="a_jump" href="javascript:;"
                 style="display: block;position: absolute;right:30px;line-height: 2.5;padding: 10px 10px;color: #17a2b8;">
        <span>退出</span>
        </a>
      </span>
      <span v-else>
        <a href="/login"
           style="display: block;position: absolute;right:30px;line-height: 2.5;padding: 10px 10px;color: #17a2b8;">
        <span>登陆</span>
        </a>
      </span>


    </el-menu>
  </nav>
  <router-view :key="$route.params.tid"></router-view>

</template>


<script>
import { Expand, Fold } from '@element-plus/icons-vue';
import Cookies from "js-cookie";
export default {
  name: "Navigation",
  data() {
    return {
      NavList: [],
      tagsurl: '/navigation/tags/',
      logouturl: this.Serverurl + '/api/logout',
      loginurl: this.Serverurl + '/api/login',
      userobj: '',
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key,keyPath)
      // location.href = key
    },
    a_jump(){
      this.$axios.get(this.logouturl).then(res =>{
        layer.msg(res.data.msg,{offset: ['25%']})
        localStorage.removeItem('user')
        // sessionStorage.setItem('logout', 'True')
        this.$router.go(0)
      })
    }

  },
  mounted() {
    this.$axios.get(this.Serverurl + '/api/login').then(res => {
      if (res.data.code != 0){
        this.$router.push({path: "/login"})
        // location = '/login'
      }
    })

    this.$axios.get(this.Serverurl + '/api/channelslog').then(res => {
      this.NavList = res.data
    })

    this.userobj = JSON.parse(localStorage.getItem('user'))
    // console.log(this.userobj)

  },
}
</script>

<style scoped>
.logo{
  font-size: 1.5rem;
  font-weight: 600;
  color: #417690 !important;
  padding: 10px 40px;

}
/*.flex-grow {*/
/*  flex-grow: 1;*/
/*}*/
/* 导航栏右偏*/
nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;

}

a {
  text-decoration: none;
  background-color: transparent;
}

</style>