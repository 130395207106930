import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
/* User CusTom */
/* element */
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// layer
import {s3Layer} from 'vue3-layer'
import 'vue3-layer/dist/s3Layer.css'
/* @kangc/v-md-editor@next */
//markdown
import VueMarkdownEditor from '@kangc/v-md-editor';
import {xss} from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import Prism from 'prismjs';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';


//line-number
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';

//vuepressTheme
VueMarkdownEditor.use(vuepressTheme, {
    Prism,
}).use(createLineNumbertPlugin());

// //githubTheme
// import hljs from 'highlight.js';
// VueMarkdownEditor.use(githubTheme, {
//   Hljs: hljs,
//   Prism,
// });
//.use(createLineNumbertPlugin()).use(createHighlightLinesPlugin());
const app = createApp(App);

import axios from "axios"

app.config.globalProperties.$axios = axios

//app.config.globalProperties.Serverurl = 'http://127.0.0.1:8000'
app.config.globalProperties.Serverurl = ''
app.config.globalProperties.api_login = '/api/login'


app.config.globalProperties.Tagsurl = '/navigation/tags/'

import Cookies from 'js-cookie'

app.config.globalProperties.$Cookies = Cookies


axios.defaults.withCredentials = true;



//pinia
// import pinia from '@/store'
// import Base64 from 'js-base64'
// import store from "@/store";


app.use(router).use(ElementPlus).use(VueMarkdownEditor).use(VMdPreview).component('s3-layer', s3Layer).mount('#app')



