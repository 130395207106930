import { createRouter, createWebHistory } from 'vue-router'
import Navigation from '../views/Navigation'
import Homepage from "@/views/Homepage";
import StartPage from '../views/StartPage'
import Login from '../views/User/Login'
import Register from '../views/User/Register'


// const routes = [
//   {
//     path: '/',
//     name: 'StartPage',
//     component: StartPage
//   },
//   {
//     path: '/Homepage',
//     name:'Homepage',
//     component: Homepage
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     component: ()=> import('../views/User/Login')
//   },
//   {
//     path: '/register',
//     name: 'Register',
//     component: ()=> import('../views/User/Register')
//   },
//   {
//     path: '/mark',
//     name: 'mark',
//     component: ()=> import('../components/marksub.vue')
//   },
//   {
//     path: '/markdown_change-:id',
//     name: 'markdown_change',
//     component: () => import('../components/marksub-change-id')
//   },
//   {
//     path: '/mark2',
//     name: 'mark2',
//     component: ()=> import('../components/marksub2.vue')
//   },
//   {
//     path: '/markshow',
//     name: 'markshow',
//     component: ()=> import('../components/markshow.vue')
//   },
//   //   {
//   //   path: '/markdown_show',
//   //   name: 'markdown_show',
//   //   component: ()=> import('../components/Markdown_show.vue')
//   // },
//   {
//     path: '/navigation',
//     name: 'Navigation',
//     component: Navigation,
//     redirect: '/navigation/first',
//     children:[
//       {path:'first', component: ()=> import('../views/Nav/FirstPage')},
//       // {path:'home', component: ()=> import('../views/HomeView')},
//       // {path:'about', component: ()=> import('../views/AboutView')},
//       // {path:'markdown_show', component: ()=> import('../components/Markdown_show')},
//       {path:'tags/:tid', component:()=> import('../views/Nav/Tag')},    //标签
//       {path:'markdown_show-:id', component: ()=> import('../components/Markdown_show-id')},
//     ]
//   },
//
//
//   // {
//   //   path: '/run',
//   //   name: 'run',
//   //   component: () => import('../components/RunImage')
//   // },
//
// ]





const routes = [
  {
    path: '/',
    name: 'StartPage',
    redirect: '/navigation/first',

  },
  {
    path: '/login',
    name: 'Login',
    meta:{
      title: 'Login'
    },
    component: () => import('../views/User/Login'),
  },
  {
    path: '/navigation',
    name: 'Navigation',
    component: Navigation,
    redirect: '/navigation/first',
    children: [
      {path: 'first', component: () => import('../views/Nav/FirstPage'),meta:{title: '首页'}},
      {path: 'tags/:tid', component: () => import('../views/Nav/Tag'),meta:{title: '标签'}},
      {path: 'markdown_show-:id', component: () => import('../components/Markdown_show-id'),meta:{title: '详细'}},
    ],
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: '注册',
    },
    component: () => import('../views/User/Register')
  },
  {
    path: '/mark',
    name: 'mark',
    meta:{
      title: '新建',
    },
    component: () => import('../components/marksub.vue'),
    beforeEnter: (to, from, next) => {
      // console.log(sessionStorage.getItem('user'))
      if (localStorage.getItem('user')) {
        next();
        return;
      }
      console.log(to)
      localStorage.setItem('next_url', JSON.stringify(to))
      next({name: 'Login'});
      return;
    },
  },
  {
    path: '/markdown_change-:id',
    name: 'markdown_change',
    meta: {
      title: '修改',
    },
    component: () => import('../components/marksub-change-id'),
    beforeEnter: (to, from, next) => {
      // console.log(sessionStorage.getItem('user'))
      if (localStorage.getItem('user')) {
        next();
        return;
      }
      localStorage.setItem('next_url', JSON.stringify(to))
      next({name: 'Login'});
      return;
    },
  },

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) =>{
  // console.log(to)
  // console.log(from)
  if (to.meta.title){
      document.title = to.meta.title
  }
  next();
  return;

})



export default router
