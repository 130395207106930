<template>
  <!--    <div class='divheader center-block'>-->
  <!--      <span>网站LOGO</span>-->
  <!--    </div>-->
  <div class="abs login "> <!-- 登录框 start -->
    <div class="divsimple textcenter">
      <h3>Welcome to Login</h3>
    </div> <!-- 占位div + 标题 -->

    <el-form :model="ModelForm" :rules="Rules_dict" ref="RefForm">

      <el-form-item prop="username">
        <label for="username_id">Username or Email</label>
        <el-input v-model="ModelForm.username" id="username_id"></el-input>
      </el-form-item>

      <el-form-item prop="password">
        <label for="password_id">Password</label>
        <el-input v-model="ModelForm.password" id="password_id" show-password
                  @keyup.enter.native="onLogin('RefForm')"></el-input>
      </el-form-item>

      <el-form-item>

        <div class="width100">
          <div class="inlineblock fleft"><a v-bind:href="Register">Register</a></div>
          <div class="inlineblock fright"><a v-bind:href="Repasswd">Forgot your password?</a></div>
        </div>


        <!--        <div class="width100 textright">-->
        <!--          <a href="">Forgot your password?</a>-->
        <!--        </div>-->
      </el-form-item>

      <el-form-item>
        <el-button type="primary width100" @click="onLogin('RefForm')">login</el-button>
      </el-form-item>

    </el-form>

  </div> <!-- 登录框 end -->
  <div class="abs img1"></div> <!-- 背景图div1 -->
  <!--  <div class="abs login_title"><h2>VLOG博客系统</h2></div> &lt;!&ndash; 标题div &ndash;&gt;-->
</template>

<script>
// import axios from 'axios'

import {AES_encode} from "@/utlis/aes";


export default {
  name: "Login",
  data() {
    return {
      Register: '/register',
      Repasswd: '/repasswd',
      ModelForm: {
        username: '',
        password: '',
      },
      Rules_dict: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 18, message: '用户名长度在3-18之间', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 18, message: '密码长度在6-18之间', trigger: 'blur'},
        ]
      },
    }
  },
  mounted() {
    // const indexlay = layer.load();
    const indexlay = layer.load(0, {offset: ['25%']})
    this.checklogin(indexlay)

  },
  methods: {


    checklogin(index) {
      this.$axios(this.Serverurl + this.api_login, {
        method: 'GET',
        // mode: 'no-cors',
        // withCredentials: true,
      }).then(res => {
        // console.log(res.data)
        if (res.data.code == 0) {
          setTimeout(function () {
            layer.close(index)
            // window.location.href= res.data.next;
            layer.msg('已登陆用户:' + res.data.user[0].username, {offset: ['25%']})
          }, 1000)
        } else {
          // layer.msg('请登录',{offset: ['25%']} )
          localStorage.removeItem('user')
          layer.close(index)
        }
      })
    },
    onLogin(formName) {
      const indexlay = layer.load(0, {offset: ['25%']});
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert(this.ModelForm);
          // console.log(JSON.stringify(this.ModelForm));
          let headers_config = {
            headers: {'Content-Type': 'multipart/form-data', 'X-CSRFToken': this.$Cookies.get('csrftoken')}
          }

          let uesrdata_aes = {
            username:AES_encode(this.ModelForm.username) ,
            password:AES_encode(this.ModelForm.password)
          }
          // console.log(uesrdata_aes)
          this.$axios.post(this.Serverurl + this.api_login, uesrdata_aes, headers_config).then(res => {
            // console.log(res)
            layer.msg(res.data.msg, {offset: ['25%']});
            if (res.data.code == 0) {

              localStorage.setItem('user', JSON.stringify(res.data.user[0]))
              let userobj = JSON.parse(localStorage.getItem('user'))
              let next_url = JSON.parse(localStorage.getItem('next_url'))

              if (next_url) {
                this.$router.push(next_url)
                localStorage.removeItem('next_url')
              } else {
                this.$router.push({path: "/navigation/first"})
              }
            }
          }).catch(err => {
            console.log(err)
            console.log('server error')
            layer.msg('服务器请求失败');
          })
        } else {
          console.log('error')
          layer.msg('请输入用户名密码');
          return false;
        }

      })
      layer.close(indexlay)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

  }

}
</script>

<style scoped>
html {
  -webkit-filter: grayscale(100%);
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

/*.login{*/
/*  width: 320px;*/
/*  height: 280px;*/
/*  background-color: white;*/
/*  padding: 30px 30px 40px 30px;   !* 上右下左 *!*/
/*  !*margin: 0 +100px;   !*div居中*!*!*/
/*  top: 20%;*/
/*  right: 10%;*/
/*  !*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.7);*!*/
/*  box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.2);*/
/*  z-index: 100;*/
/*}*/

@media screen and (max-width: 420px) {
  /* 小屏幕-屏幕宽度小于420px */
  .login {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: white;
    padding: 30px 30px 40px 30px; /* 上右下左 */
    box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;
    border-radius: 5px;
  }

  .divsimple {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 420px) {
  /* 大屏幕-屏幕宽度大于420px */
  .login {
    width: 320px;
    height: 280px;
    background-color: white;
    padding: 30px 30px 40px 30px; /* 上右下左 */
    /*margin: 0 +100px;   !*div居中*!*/
    top: 20%;
    right: 10%;
    /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.7);*/
    box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;
    border-radius: 5px;
  }
}


a {
  text-decoration: none;
  color: #0056b3;
}

a:hover {
  text-decoration: underline;
  color: orangered;
}

.img1 {
  top: 0;
  left: 0;
  bottom: 0;
  width: 80%;
  opacity: 0.7;
  background-image: url('/public/img/海洋.jpg');
  background-repeat: no-repeat;
  background-position-x: 74%;
  background-position-y: 58%;
  z-index: 1;
}

/*
.login_title{
  top: 22%;
  right: 22%;
  color:#4e7d8ac7;
  width: 100%;
  height: 60px;
  line-height: 30px;
  opacity: 0.9;
  white-space: nowrap;
  background-color: #FFFFFF;
}*/

</style>